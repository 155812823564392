/* CSS Document */

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);

h1 {
    font-size: 20px;
    text-align: center;
}

h3 {
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #fff;
}

h3 a {
    color: #fff;
}

a {
    color: #0a0e13;
}

h1 {
    text-align: center;
    font-size: 20px;

    font-family: "Bree Serif", "serif";
}

#container {
    margin: 0 auto;
    max-width: 990px;
}

/* p {
    text-align: center;
} */

.toggle,
[id^="drop"] {
    display: none;
}

/* Giving a background-color to the nav container. */
nav {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    background: rgba(255, 255, 255, 0.1);
}

/* #logo {
    display: flex;
    align-items: center;
    padding: 0 30px;
    float: left;
    font-size: 25px;
    color: #fff;
    font-weight: bold;
}

#logo img {
    margin: 10px;
} */

.logo {
    margin-top: 5px;
    font-size: 20px;
    align-items: center;
    width: 350px;
    
}

/* Since we'll have the "ul li" "float:left"
  * we need to add a clear after the container. */

nav:after {
    content: "";
    display: table;
    clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
  * and adding "position:reltive" */
nav ul {
    float: right;
    padding: 0;
    margin-top: 15px;
    text-align: center;
    list-style: none;
    position: relative;
}

/* Positioning the navigation items inline */
nav ul li {
    margin: 0px;
    display: inline-block;
    text-align: left;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

/* Styling the links */
nav a {
    display: block;
    padding: 11px 15px;
    color: #141414;
    font-size: 17px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
}

nav ul li ul li:hover {
    background: #dddddd;
}

/* Background color change on Hover */
/* nav a:hover { 
     background-color: #2aaac1; 
     padding: 5px;margin: 0;
 } */

/* Hide Dropdowns by Default
  * and giving it a position of absolute */
nav ul ul {
    display: none;
    position: absolute;
    z-index: 1;
    /* has to be the same number as the "line-height" of "nav a" */
    top: 35px;
}

/* Display Dropdowns on Hover */
nav ul li:hover>ul {
    display: inherit;
    background: rgba(255, 255, 255, 0.753);
}

/* Fisrt Tier Dropdown */
nav ul ul li {
    width: 250px;
    float: none;
    display: list-item;
    position: relative;
    line-height: 1;
}

/* Second, Third and more Tiers	
  * We move the 2nd and 3rd etc tier dropdowns to the left
  * by the amount of the width of the first tier.
 */
nav ul ul ul li {
    position: relative;
    top: -60px;
    /* has to be the same number as the "width" of "nav ul ul li" */
    left: 170px;
}

/* Change ' +' in order to change the Dropdown symbol */
li>a:after {
    content: "";
}

li>a:only-child:after {
    content: "";
}

/* Media Queries
 --------------------------------------------- */

@media all and (max-width: 1200px) {
    #logo {
        display: block;
        align-items: center;
        padding: 0;
        width: 100%;
        text-align: start;
        float: none;
        display: flex;
        justify-content: space-between;
    }

    /* nav {
        margin: 0;
        margin-top: -50px;
    } */

    .menu {
        background-color: #2aaac1;
        width: 100%;
    }

    /* Hide the navigation menu by default */
    /* Also hide the  */
    .toggle+a,
    .menu {
        display: none;
    }

    /* Stylinf the toggle lable */
    .toggle {
        display: block;
        float: right;
        padding: 14px 20px;
        color: #fff;
        font-size: 17px;
        text-decoration: none;
        border: none;
    }

    @media screen and (max-width: 320px) {
        .toggle {
            margin-top: -30px !important;
        }

        #logo img {
            margin: 0;
        }
    }

    .toggle:hover {
        background-color: #2aaac1;
    }


  .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #333;
      padding: 10px 20px;
  }
    /* Display Dropdown when clicked on Parent Lable */
    [id^="drop"]:checked+ul {
        display: block;
    }

    /* Change menu item's width to 100% */
    nav ul li {
        display: block;
        width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
        padding: 0 40px;
    }

    nav ul ul ul a {
        padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
        background-color: #c9c9c9;
    }

    nav ul li ul li .toggle,
    nav ul ul a,
    nav ul ul ul a {
        padding: 14px 20px;
        color: #fff;
        font-size: 17px;
    }

    nav ul li ul li .toggle,
    nav ul ul a {
        background-color: #dfdfdf;
    }

    /* Hide Dropdowns by Default */
    nav ul ul {
        float: none;
        position: static;
        color: #ffffff;
        /* has to be the same number as the "line-height" of "nav a" */
    }

    /* Hide menus on hover */
    nav ul ul li:hover>ul,
    nav ul li:hover>ul {
        display: block;
    }

    /* Fisrt Tier Dropdown */
    nav ul ul li {
        display: block;
        width: 100%;
    }

    nav ul ul ul li {
        position: static;
        /* has to be the same number as the "width" of "nav ul ul li" */
    }
}

@media all and (max-width: 330px) {
    nav ul li {
        display: block;
        width: 94%;
    }
}

.clock {
    float: right !important;
    position: absolute;
    color: #fff;
    z-index: 1;
    margin-top: 20px;
    padding: 10px;
}

.full_header {
    margin: 0;
    padding: 0;
}

.language-select {
    margin-left: auto;
    color: white;
}

.language-select label {
    margin-right: 10px;
}

.language-select select {
    padding: 5px;
    font-size: 16px;
}



.logo {
    color: #1F1F1F;
    font-size: 24px;
    left: 200px;
}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    margin-left: 20px;
}

.nav-links a {
    color: #000000;
    text-decoration: none;
    font-size: 18px;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger span {
    height: 3px;
    width: 25px;
    background: #000000;
    margin: 4px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: #ffffff;
    }

    .nav-links.active {
        display: flex;
    }

    .hamburger {
        display: flex;
    }
}

.test-mode-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffcc00;
    /* Yellow background */
    color: #000;
    /* Black text */
    text-align: center;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    z-index: 1000;
    /* Ensure it stays on top of other content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}