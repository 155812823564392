/* .zoomable-image {
    width: 300px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.zoomable-image:hover {
    transform: scale(2.0);
} */
/* Hover effekti o'chirilgan */
.zoomable-image {
    cursor: pointer; /* Kursor ko'rsatkichini qo'shamiz */
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
}