
.carousel{
        height: 960px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
}
.carousel-item img{
    min-width: 100%;
    max-height: 90vh;
}
.carousel-caption h3{
    color: #234262;
    font-family: "Abril Fatface", cursive;
    font-size: 65px;
    font-weight: 400;
    line-height: 1.16;
    margin-bottom: 30px;
    margin-right: 500px;
    text-align: left;
        
}
.carousel-caption p {
    color: #1f2327;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.16;
    margin-bottom: 300px;
    margin-right: 500px;
    text-align: left;
}
.slider_text h3 {
    color: #234262;
    font-family: "Abril Fatface", cursive;
    font-size: 65px;
    font-weight: 400;
    line-height: 1.16;
    margin-bottom: 200px;
}

.visually-hidden{
    position: relative;
        opacity: 0;
}
.carousel-indicators {
   padding-left: 10px;
   margin-left: 5px;
   display: none !important;
}
@media screen and (max-width: 1420px) {
    .slider_text h3 {
            color: #234262;
            font-family: "Abril Fatface", cursive;
            font-size: 55px;
            font-weight: 400;
            line-height: 1.16;
            margin-bottom: 100px;
        }
}
@media screen and (max-width: 992px) {
    .slider_text h3 {
        color: #234262;
        font-family: "Abril Fatface", cursive;
        font-size: 45px;
        font-weight: 400;
        line-height: 1.16;
        margin-bottom: 50px;
    }
}
@media screen and (max-width: 768px) {
    .slider_text h3 {
        color: #234262;
        font-family: "Abril Fatface", cursive;
        font-size: 25px;
        font-weight: 400;
        line-height: 1.16;
        margin-bottom: 0px;
    }
}